import { App } from 'vue'

export default {
  install: (app: App<any>) => {
    const formatDate = (date: string | Date) => {
      if (_.isEmpty(date)) {
        return '---'
      }

      const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }

      if (typeof date === 'string') {
        date = new Date(date)
      }

      return new Date(date).toLocaleDateString('pt-BR', dateOptions)
    }

    const getDayOfWeek = (date: Date) => {
      const daysOfWeek = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
      ]
      const dayIndex = date.getDay()
      return daysOfWeek[dayIndex]
    }

    const formatRegistrationDate = (date: Date) => {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }

    const formatRegisterDate = (date: string) => {
      const publicationDate = new Date(date)
      const now = new Date()
      const oneDay = 24 * 60 * 60 * 1000
      const timeDiff = now.getTime() - publicationDate.getTime()

      if (timeDiff < oneDay && now.getDate() === publicationDate.getDate()) {
        return publicationDate.toLocaleTimeString(
          Intl.DateTimeFormat().resolvedOptions().locale,
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )
      } else if (timeDiff < 2 * oneDay) {
        return 'Ontem'
      } else if (timeDiff < 7 * oneDay) {
        return getDayOfWeek(publicationDate)
      } else {
        return formatRegistrationDate(publicationDate)
      }
    }

    app.provide('formatDate', formatDate)
    app.provide('formatRegisterDate', formatRegisterDate)
    app.config.globalProperties.$formatDate = formatDate
    app.config.globalProperties.$formatRegisterDate = formatRegisterDate
  },
}
