import { App } from 'vue'

export default {
  install: (app: App<any>) => {
    const formatTime = (time: string | Date) => {
      if (typeof time === 'string') {
        time = new Date(time)
      }

      return new Intl.DateTimeFormat('default', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
      }).format(time)
    }

    app.provide('formatTime', formatTime)
    app.config.globalProperties.$formatTime = formatTime
  },
}
