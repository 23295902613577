import { App } from 'vue'

export default {
  install: (app: App<any>) => {
    const translateDays = (day: string) => {
      const days: { [key: string]: string } = {
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
        sunday: 'Domingo',
      }

      return days[day]
    }

    app.provide('translateDays', translateDays)
    app.config.globalProperties.$translateDays = translateDays
  },
}
